<template>
  <div class="h-full w-full bg-white">
    <div class="p-v-10 m-h-40 flex b-b">
      <ButtonGroup v-if="getSelectLength>0">
        <Button @click="onPatchCancelCollect">取消收藏</Button>
      </ButtonGroup>
      <div class="flex-1"></div>
      <Input class="w-300" style="display: none" placeholder="搜索文件/文件夹" search v-model="searchText"></Input>
    </div>
    <div class="flex-s-0 b-r p-h-40 fs-14">
      <ul class="flex b-b">
        <li :class="{'actives-item-style':currectIndex === item.id}" :key="index" @click="onMenuItemClick(item)"
            class="h-30 lh-30 m-t-10 p-h-20"
            v-for="(item,index) in innerMenus">
          <div :class="{'actives-items-style':currectIndex === item.id}">{{item.name}}</div>
        </li>
      </ul>
    </div>
    <div class="flex w-full" style="height: calc(100% - 1px);">
      <div class="flex-1 o-y-auto noscroll" ref="scrollview">
        <div class="flex a-center p-h-40 m-v-10 m-l-10">
          <div class="flex-1 v-a-middle fs-14 flex">
            <span class="bold">收藏 </span> <span>  > {{getshowLable}}</span>
            <span v-if="breadcrumbList.length > 1">
              <span @click="onBackClick"
                    style="color: #1385EA">返回上一级</span> |
              <span>{{breadcrumbList[breadcrumbList.length-1].name}}</span>
            </span>
          </div>

          <div class="flex a-center flex-s-0 m-l-10">
            <img @click="onShowTypeChange(1)" class="w-20 h-20" src="../../assets/common/ic_liebiao_noselected.png"
                 v-if="!isList">
            <img @click="onShowTypeChange(1)" class="w-20 h-20" src="../../assets/common/ic_liebiao_selected.png"
                 v-if="isList">
            <img @click="onShowTypeChange(0)" class="w-20 h-20 m-l-10"
                 src="../../assets/common/ic_tuwen_selected.png"
                 v-if="!isList">
            <img @click="onShowTypeChange(0)" class="w-20 h-20 m-l-10"
                 src="../../assets/common/ic_tuwen_noselected.png"
                 v-if="isList">
          </div>
        </div>
        <div class="p-h-40">
          <div v-if="isList">
            <!--:height="tableHeight"-->
            <Table :columns="columns" :data="dataList" @on-row-click="onTableItemClick"
                   @on-selection-change="onSelectionChange">

              <template slot="operation" slot-scope="{ row, index }">
                <slot :item="row" name="minopera">
                  <div class="flex a-center j-center">
                    <!--<img @click="onPreClick(row)" alt="" class="w-20 h-20" src="../../assets/source/ic_link.png">-->
                    <img @click.stop="onCancelCollectClick(row)" alt="" class="w-20 h-20 m-l-25"
                         src="../../assets/common/ic_shoucang_selected.png">
                    <!--<img @click="onFileEdit(row)" alt="" class="w-20 h-20 m-l-25"-->
                    <!--src="../../assets/source/ic_xiugai.png">-->
                    <!--<img @click="ondel(row)" alt="" class="w-20 h-20 m-l-25" src="../../assets/source/ic_del_th.png">-->
                  </div>
                </slot>
              </template>
              <template slot="name" slot-scope="{ row,index }">
                <div class="flex a-center">
                  <file-icon :file="row"
                             class="m-r-10"/>
                  <span v-show="!row.isEdit">{{row.name}}</span>
                  <div @click.stop class="rename"
                       v-show="row.isEdit">
                    <Input :ref="'input'+index"
                           @on-focus="onFocus($event)"
                           v-model="dataList[index].editName"/>
                    <img @click.stop="onClickRenameOk(dataList[index])"
                         src="../../assets/common/addfolder_icon_ok.png">
                    <img @click.stop="onClickRenameCancle(dataList[index],index)"
                         src="../../assets/common/addfolder_icon_cancle.png">
                  </div>
                </div>
              </template>
              <template slot="checkbox"
                        slot-scope="{ row,index }">
                <div @click.stop>
                  <Checkbox @on-change="onSelectionChange"
                            v-model="dataList[index].ischeck"></Checkbox>
                </div>
              </template>
            </Table>
          </div>
          <div class="p-h-15" v-if="!isList">
            <ul class="flex m-l--15 m-r--15 flex-wrap">
              <li :key="index" @click="onSourceItemClick(item)" v-for="(item,index) in dataList">
                <div class="p-10 m-5 item-style relative">
                  <div class="w-120 m-v-5 relative" style="height: 120px;" v-if="showAlbums(item)">
                    <img :src="getCoverUrl(item)" alt="" class="h-full" style="width: 118px">
                    <div class="h-full flex a-center j-center absolute left-0 top-0" style="width: 118px"
                         v-if="item.contentType === 2">
                      <img alt="" class="w-24 h-24" src="../../assets/common/imgview_icon_player.png">
                    </div>
                  </div>
                  <div class="w-120 m-v-5 relative flex a-center j-center" style="height: 120px;"
                       v-if="!showAlbums(item)">
                    <img :src="getCoverUrl(item)" alt="" style="width: 52px;height:65px">
                  </div>
                  <div class="w-120 lin2" style="word-break: break-all;margin-top: 0px;"
                       v-if="!item.isEdit">
                    {{item.name}}
                  </div>
                  <div @click.stop class="w-120" style="word-break: break-all;margin-top: 0px;"
                       v-if="item.isEdit">
                    <Input v-model="item.editName"></Input>
                    <div class="flex j-end a-center">
                      <img @click.stop="onClickRenameOk(item)"
                           src="../../assets/common/addfolder_icon_ok.png">
                      <img @click.stop="onClickRenameCancle(item,index)"
                           src="../../assets/common/addfolder_icon_cancle.png">
                    </div>
                  </div>
                  <!--<div class="clear w-full p-h-10">-->
                  <!--<div class="fr" style="color:#257DE5;display: none"-->
                  <!--v-clipboard:copy="item.copylink"-->
                  <!--v-clipboard:error="onError"-->
                  <!--v-clipboard:success="copy">复制链接-->
                  <!--</div>-->
                  <!--</div>-->
                  <div :selectMode="showSelect" @click.stop class="absolute left-5 top-5 checkstyle">
                    <checkbox v-model="item.ischeck"></checkbox>
                  </div>
                  <!--<div class="absolute right-5 top-5" v-if="getshowTypeIcon(item)">-->
                  <!--<img :src="getshowIcon(item)" alt="" class="w-20 h-20">-->
                  <!--</div>-->
                </div>
              </li>
            </ul>
          </div>
        </div>
        <infinite-loading :distance="10"
                          @infinite="infiniteHandler"
                          ref="infiniteLoading">
          <div class="total"
               slot="no-more">共{{dataList.length}}项
          </div>
        </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import FileIcon from '../../components/common/FileIcon'

export default {
  name: 'CollectionList',
  components: {FileIcon},
  data () {
    return {
      searchText: '',
      currectIndex: 0,
      innerMenus: [{
        id: 0,
        name: '全部'
      }, {
        id: 3,
        name: 'DOC'
      }, {
        id: 4,
        name: 'XLS'
      }, {
        id: 5,
        name: 'PPT'
      }, {
        id: 6,
        name: 'PDF'
      },
        //   {
        //   id: 1,
        //   name: '图片'
        // }, {
        //   id: 2,
        //   name: '视频'
        // }
      ],
      columns: [{
        type: 'selection',
        width: 60,
        align: 'center'
      }, {
        title: '文件名称',
        align: 'center',
        key: 'name',
        className: 'demo-table-info-column',
        slot: 'name'
      }, {
        title: '创建时间',
        align: 'center',
        width: 210,
        key: 'collectTime'
      }, {
        title: '文件大小',
        align: 'center',
        width: 160,
        key: 'sizes'
      }, {
      //   title: '创建人',
      //   align: 'center',
      //   width: 160,
      //   key: 'creater'
      // }, {
      //   title: '权限',
      //   align: 'center',
      //   width: 160,
      //   key: 'persiton'
      // }, {
        title: '操作',
        align: 'center',
        width: 160,
        slot: 'operation',
      }],
      dataList: [],
      breadcrumbList: [],
      pageNum: 1,
      pageSize: 20,
    }
  },
  computed: {
    getSelectLength () {
      let length = 0
      if (this.dataList && this.dataList.length) {
        let list = this.dataList.filter(res => {
          return res.ischeck
        })
        if (list && list.length) {
          length = list.length
        }
      }
      return length
    },
    isList () {
      return parseInt(this.$store.state.disk.isShowList)
    },
    getshowLable () {
      let text = ''
      let list = this.innerMenus.filter(res => {
        return res.id === this.currectIndex
      })
      if (list && list.length) {
        text = list[0].name
      }
      return text
    },
    showSelect () {
      if (this.dataList && this.dataList.length) {
        let list = this.dataList.filter(res => {
          return res.ischeck
        })
        if (list && list.length) {
          return true
        }
      }
      return false
    },
    isCheckAll () {
      if (this.dataList.length === 0) {
        return false
      } else {
        let selList = this.dataList.filter(res => {
          return res.ischeck
        })
        if (selList.length === this.dataList.length) {
          return true
        } else {
          return false
        }
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      for (const i in this.columns) {
        let item = this.columns[i]
        if (item.type === 'selection') {
          this.$delete(item, 'type')
          this.$set(item, 'slot', 'checkbox')
          this.$set(item, 'renderHeader', (h, params) => {
            return h('Checkbox', {
              props: {
                value: this.isCheckAll,
              },
              on: {
                'on-change': this.onChangeCheckAll
              }
            })
          })
        }
      }
    },
    onChangeCheckAll (boo) {
      this.dataList.forEach(item => {
        item.ischeck = boo
      })
    },
    onMenuItemClick (item) {
      this.currectIndex = item.id
      this.reloadData()
    },
    reloadData () {
      this.dataList = []
      this.pageNum = 1
      this.$nextTick(() => {
        this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
      })
    },
    onTableItemClick (item) {
      if (item.isEdit) {
        return
      }
      let url = item.contentUrl
      if (item.contentType !== 6) {
        url = 'http://47.104.70.223:8012/onlinePreview?url=' + encodeURIComponent(this.$BaseSix.encode(item.contentUrl))
      }
      window.open(url)
    },
    onSelectionChange (e) {

    },
    infiniteHandler ($state) {
      this.$nextTick(() => {
        this.getCollectLists($state)
      })
    },
    async getCollectLists ($state) {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        relationType: 1,
        folderType: 1
      }
      if (this.currectIndex) {
        params.contentType = this.currectIndex
      }
      let res = await this.getCollectList(params)
      if (res.code === 0) {
        let list = res.data.list.map((resl, index) => {
          resl.index = index + 1
          resl.copylink = resl.link ? resl.link.link || '' : ''
          let name = resl.title || ''
          resl.type = resl.relationType
          resl.coverUrl = resl.contentUrl || ''
          resl.name = name || ''
          resl.persiton = '-'
          resl.ischeck = false
          resl.isEdit = false
          resl.objType = 1
          resl.sizes = '-'
          return resl
        })
        if (this.pageNum === 1) {
          this.dataList = list
        } else {
          this.dataList = this.dataList.concat(list)
        }
        if (list && list.length < 1) {
          $state.complete()
        } else {
          this.pageNum++
          if (this.isList) {
            this.$refs.scrollview.scrollTo(0, this.$refs.scrollview.scrollTop - 20)
          }
          this.$nextTick(() => {
            $state.loaded()
          })
        }
      } else {
        $state.complete()
      }
    },
    showAlbums (item) {
      if (item.contentType === -1 || item.contentType === 1 || item.contentType === 2) {
        return true
      } else {
        return false
      }
    },
    getCoverUrl (item) {
      if (item.contentType === 5) {
        return require('../../assets/source/imgview_icon_ppt.png')
      } else if (item.contentType === 3) {
        return require('../../assets/source/imgview_icon_word.png')
      } else if (item.contentType === 4) {
        return require('../../assets/source/imgview_icon_excle.png')
      } else if (item.contentType === 6) {
        return require('../../assets/source/imgview_icon_pdf.png')
      } else if (item.contentType === 2) {
        return item.coverUrl + '?x-oss-process=video/snapshot,t_1000,ar_auto'
      } else {
        return item.coverUrl
      }
    },
    onShowTypeChange (type) {
      this.setMenuShowList(type)
    },
    onCancelCollectClick (row) {
      let params = {
        relationType: row.relationType,
        relationIdList: [{
          id: row.relationId
        }]
      }
      this.cancelCollect(params).then(res => {
        if (res.code === 0) {
          this.reloadData()
        }
      })
    },
    onPatchCancelCollect () {
      let list = this.dataList.filter(res => {
        return res.ischeck
      })
      if (list && list.length) {
        let params = {
          relationType: 1,
        }
        let relationIdList = list.map(res => {
          return {
            id: res.relationId
          }
        })
        params.relationIdList = relationIdList
        this.cancelCollect(params).then(res => {
          if (res.code === 0) {
            this.reloadData()
          }
        })
      }
    },
    onSourceItemClick (item) {
      if (item.isEdit) {
        return
      }
      let url = item.contentUrl
      if (item.contentType !== 6) {
        url = 'http://47.104.70.223:8012/onlinePreview?url=' + encodeURIComponent(this.$BaseSix.encode(item.contentUrl))
      }
      window.open(url)
    },
    ...mapActions([
      'setMenuShowList',
      'getCollectList',
      'collectFile',
      'cancelCollect'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .w-300 {
    width: 300px;
  }

  .item-style:hover {
    background: #F4F4F4;
  }

  .item-style {
    .checkstyle {
      display: none;
    }

    .checkstyle[selectMode=true] {
      display: block;
    }
  }

  .item-style:hover {
    .checkstyle {
      display: block;
    }
  }

  .actives-item-style {
    /*background: #F1F8FF;*/
    color: #1385EA;
  }

  .actives-items-style {
    border-bottom: 2px solid #1385EA;
  }

  .rename {
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .total {
    font-size: 14px;
    color: #666;
    margin-top: 20px;
  }
</style>

<style>
  .ivu-table td.demo-table-info-column {
    text-align: left;
  }
</style>
